<template>
  <div id="branch">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-5">
          <i class="fas fa-charging-station"></i>
          {{ branch.name }}
          <button type="button" class="button is-small is-link" style="float:right;" @click="new_node = true">
            <span class="mr-2 is-hidden-mobile">Create Node</span>
            <i class="fas fa-plus"></i>
          </button>
        </strong>
        <hr>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <small class="is-size-7 is-hidden-mobile" style="float:right;">{{ Nodes.length }} Node(s)</small>
          <ul>
            <li><a @click="redirect('/member/mornitor')">Plants</a></li>
            <li class="is-active"><a>{{ branch.name }}</a></li>
          </ul>
        </nav>
        <div class="has-text-centered" v-if="!Nodes.length">
          <hr>You didn't create any node!
        </div>
        <div class="columns is-multiline" v-else>
          <div class="column is-one-third-desktop px-1"
            v-for="(val, key) in                                                                                       Nodes                                                                                      "
            :key="key">
            <article class="message is-link">
              <div class="message-header py-1">
                <p class="w-100" :class="{ 'cursor_pointer': parseInt(val.type) !== 2 }" @click="go_to_node(val)">
                  <i class="fas fa-code-branch"></i>
                  {{ val.name }} ({{ val.mac }})
                </p>
                <div class="dropdown is-right is-hoverable">
                  <div class="dropdown-trigger">
                    <button class="button is-xs">
                      <i class="fas fa-chevron-down"></i>
                    </button>
                  </div>
                  <div class="dropdown-menu">
                    <div class="dropdown-content">
                      <a class="dropdown-item" @click="node = val; new_node = true;">
                        Edit/ Update
                      </a>
                      <hr class="dropdown-divider">
                      <a class="dropdown-item has-text-danger" @click=" remove_node(val)">
                        Remove
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="message-body has-text-left py-3" :class="{ 'cursor_pointer': parseInt(val.type) !== 2 }"
                @click=" go_to_node(val)">
                <small class="is-size-6 is-size-7-mobile">{{ val.detail }}</small>
                <div class="blobs-container" style="float:right;" v-if="parseInt(val.type) == 2">
                  <div class="blob" :class="connect_status(val)"></div>
                </div>
              </div>
              <div class="message-footer is-size-6 is-size-7-mobile px-2"> <!-- v-if="val.on!==null" -->
                <!-- parseInt(val.enable) && val.log_created -->
                <div class="field pl-1" v-if="parseInt(val.type) == 2">
                  <center class="py-1" v-if="val.turning_on || val.turning_off">
                    <small v-if="val.turning_on">Turning on: please wait until device turn on.</small>
                    <small v-if="val.turning_off">Turning off: please wait until device turn off.</small>
                  </center>
                  <div v-else>
                    <input :id="val.mac" type="checkbox" v-model="val.on" class="switch is-rounded is-success is-small"
                      @change=" change_switch(val)" :disabled="parseInt(val.connect) !== 1">
                    <label :for="val.mac">Inverter status: <b class="pl-1">{{ val.on ? 'On' : 'Off' }}</b> </label>
                    <div class="float-right pt-0">
                      <div class="dropdown is-right is-hoverable">
                        <div class="dropdown-trigger">
                          <button class="button is-small px-2" style="border-radius:5px" @click=" openTimer(val)">
                            <i class="fas fa-wifi"></i>
                            <span class="ml-1">Timer</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- <pre>{{val}}</pre> -->
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>

    <modal-node :show="new_node" :node="node" @submit="submit_node" @close=" new_node = false; node = {};"></modal-node>

    <div class="modal" :class="{ 'is-active': show_timer }">
      <div class="modal-background"></div>
      <div class="modal-card has-text-left">
        <header class="modal-card-head py-3">
          <p class="modal-card-title is-size-5 is-size-6-mobile">Set Timer ({{ node.mac }}) </p>
          <button class="delete" @click="closeTimer"></button>
        </header>
        <form @submit.prevent=" saveTimer(val)">
          <section class="modal-card-body py-0">
            <div class="row" :class="{ 'border-bottom': Inverter_Remote == 'auto' }">
              <div class="col-sm-6 py-2" :class="{ 'border-right': Inverter_Remote == 'auto' }">
                <div class="field">
                  <label class="label has-text-left">Inverter_Remote</label>
                  <div class="control is-expanded">
                    <div class="control">
                      <label class="radio">
                        <input type="radio" v-model="Inverter_Remote" value="manual" required>
                        <small class="ml-1">Manual</small>
                      </label>
                      <label class="radio">
                        <input type="radio" v-model="Inverter_Remote" value="auto" required>
                        <small class="ml-1">Auto</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 py-2" v-if="Inverter_Remote == 'auto'">
                <div class="field">
                  <label class="label has-text-left">Active_power_Control <i class="is-size-7">( {{ percent }} )</i>
                  </label>
                  <input class="slider is-fullwidth is-info" step="1" min="0" max="100" v-model="percent" type="range">
                  <!-- <div class="control is-expanded">
                    <div class="control">
                      <label class="radio">
                        <input type="radio" v-model="percent" value="0">
                        <small class="ml-1">Off</small>
                      </label>
                      <label class="radio">
                        <input type="radio" v-model="percent" value="100">
                        <small class="ml-1">On</small>
                      </label>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="row" v-if="Inverter_Remote == 'auto'">
              <div class="col-sm-6 pt-1 pb-0" :class="{ 'border-right': Inverter_Remote == 'auto' }">
                <div class="field">
                  <label class="label has-text-left">Time_Off <small class="is-size-7 float-right">(Day / Hour /
                      Minute)</small> </label>
                  <div class="columns is-mobile">
                    <div class="column is-one-third-mobile has-text-centered">
                      <div class="select w-100 is-small">
                        <select class="w-100" v-model="Time_Stop_d">
                          <option
                            v-for="                                                                                      num                                                                                       in                                                                                       32                                                                                      "
                            :key="num - 1" :value="pad2(num - 1)">
                            {{ pad2(num - 1) }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="column is-one-third-mobile has-text-centered px-0">
                      <div class="select w-100 is-small">
                        <select class="w-100" v-model="Time_Stop_h">
                          <option
                            v-for="                                                                                      num                                                                                       in                                                                                       24                                                                                      "
                            :key="num - 1" :value="pad2(num - 1)">
                            {{ formatAMPM(num - 1) }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="column is-one-third-mobile has-text-centered">
                      <div class="select w-100 is-small">
                        <select class="w-100" v-model="Time_Stop_m">
                          <option
                            v-for="                                                                                      num                                                                                       in                                                                                       60                                                                                      "
                            :key="num - 1" :value="pad2(num - 1)">
                            {{ pad2(num - 1) }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <p class="control is-expanded" hidden>
                    <input class="input is-small" type="text" :value="Time_Stop">
                  </p>
                </div>
              </div>
              <div class="col-sm-6 pt-1 pb-0">
                <div class="field">
                  <label class="label has-text-left">Time_On <small class="is-size-7 float-right">(Day / Hour /
                      Minute)</small> </label>
                  <div class="columns is-mobile">
                    <div class="column is-one-third has-text-centered">
                      <div class="select w-100 is-small">
                        <select class="w-100" v-model="Time_Start_d">
                          <option
                            v-for="                                                                                      num                                                                                       in                                                                                       32                                                                                      "
                            :key="num - 1" :value="pad2(num - 1)">
                            {{ pad2(num - 1) }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="column is-one-third has-text-centered px-0">
                      <div class="select w-100 is-small">
                        <select class="w-100" v-model="Time_Start_h">
                          <option
                            v-for="                                                                                      num                                                                                       in                                                                                       24                                                                                      "
                            :key="num - 1" :value="pad2(num - 1)">
                            {{ formatAMPM(num - 1) }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="column is-one-third has-text-centered">
                      <div class="select w-100 is-small">
                        <select class="w-100" v-model="Time_Start_m">
                          <option
                            v-for="                                                                                      num                                                                                       in                                                                                       60                                                                                      "
                            :key="num - 1" :value="pad2(num - 1)">
                            {{ pad2(num - 1) }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <p class="control is-expanded" hidden>
                    <input class="input is-small" type="text" :value="Time_Start">
                  </p>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot py-2">
            <button type="submit" class="button is-success is-small px-5">Save</button>
          </footer>
        </form>
      </div>
    </div>

  </div>
</template>

<!-- "Inverter_Remote" : "auto/manual" ,"Time_Start" :"180600" , "Time_Stop" : "181530" , "percent" : "1-100" -->

<script>
import ModalNode from '@/components/Modal/Node'

export default {
  name: 'MemberBranch',
  components: { ModalNode },
  props: {
    loaded: Object,
    branchs: Array,
    nodes: Array,
    client: Object,
    connected: Boolean,
    // topic: String,
    topic: Array,
  },
  data() {
    return {
      b_id: this.$route.params.id,
      branch: {},
      new_node: false,
      node: {},

      show_timer: false,

      Inverter_Remote: "manual",
      Time_Stop_d: "00",
      Time_Stop_h: "00",
      Time_Stop_m: "00",
      Time_Start_d: "00",
      Time_Start_h: "00",
      Time_Start_m: "00",
      percent: "0",
    }
  },
  created() {
    const INDEX = this.branchs.map((b) => parseInt(b.id)).indexOf(parseInt(this.b_id));
    if (INDEX !== -1) {
      this.branch = this.branchs[INDEX];
    } else {
      this.redirect("/member/mornitor")
    }
  },
  mounted() {
    // console.log(this.loaded.get_node);
    if (!this.loaded.get_node) {
      this.$emit('get_node', this.b_id);
    } else {
      const INDEX_ = this.loaded.get_node.map((n_id) => parseInt(n_id)).indexOf(parseInt(this.b_id));
      if (INDEX_ == -1) {
        this.$emit('get_node', this.b_id);
      }
    }

    setTimeout(() => {
      this.Nodes.forEach((node, i) => {
        if (this.client) {
          this.$emit("subscribeMQTT", node.mac, node.type);
        } else {
          this.$emit("connectMQTT", node.mac, node.type);
        }
        setTimeout(() => this.isOnline(node), 100 * i);
      });
    }, 100);
  },
  methods: {
    connect_status(val) {
      switch (val.connect) {
        case 1:
          return "green"
        case 0:
          return "red"
        default:
          return "yellow"
      }
    },
    go_to_node(val) {
      if (parseInt(val.type) == 1) this.redirect('/member/node/' + val.id);
      else if (parseInt(val.type) == 3) this.redirect('/member/node_logger/' + val.id);
    },
    submit_node(input) {
      if (!input.id) {
        const INDEX = this.nodes.map((n) => n.mac).indexOf(input.mac);
        if (INDEX !== -1) {
          this.$swal({
            title: "ไม่สามารถเพิ่มอุปกรณ์ได้!",
            text: "คุณมีอุปกรณ์หมายเลขนี้อยู่แล้ว \n (Mac Address: " + input.mac + ")",
            className: "text-center",
            icon: "warning",
          });
        } else {
          this.submit_new_node(input);
          this.new_node = false;
        }
      } else {
        if (input.mac !== this.node.mac) {
          this.$swal({
            title: "เปลี่ยนหมายเลขอุปกรณ์?",
            text: "คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนหมายเลขอุปกรณ์นี้ \n\n (หมายเลขเดิม: " + this.node.mac + ") \n (หมายเลขใหม่: " + input.mac + ")",
            className: "text-center",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
            .then((willDelete) => {
              if (willDelete) {
                this.submit_edit_node(input);
                this.new_node = false;
              }
            });
        } else {
          this.submit_edit_node(input);
          this.new_node = false;
        }
      }
    },
    async submit_new_node(input) {
      console.log(input);
      var url = this.api + 'node/add/' + this.secret;
      var data = new FormData();
      data.set('b_id', this.b_id);
      // data.set('mac', input.mac);
      data.set('mac', input.mac.toLowerCase());
      data.set('type', input.type);
      data.set('name', input.name);
      data.set('detail', input.detail);
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.nodes.push(response.data.data);
            this.$swal("", "เพิ่มอุปกรณ์ใหม่", "success");
          } else {
            this.$swal("", response.data.data, "warning");
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
    },
    async submit_edit_node(input) {
      var url = this.api + 'node/save/' + this.secret;
      var data = new FormData();
      Object.keys(input).forEach((key) => {
        data.set(key, input[key]);
      });
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            Object.keys(input).forEach((key) => {
              this.node[key] = input[key];
            });
            this.node = {};
            this.$swal("", "Successful Save", "success");
          } else {
            this.$swal("", response.data.data, "warning");
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
    },
    remove_node(val) {
      this.$swal({
        title: "Remove Node?",
        text: "Name: " + val.name + "\n (Mac Address: " + val.mac + ")",
        className: "text-center",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            this.$swal({
              title: "Are you sure?",
              text: "You want to remove this node? \n (Mac Address: " + val.mac + ")",
              className: "text-center",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
              .then(async (willDelete) => {
                if (willDelete) {
                  var url = this.api + 'node/delete/' + this.secret;
                  var data = new FormData();
                  data.set("id", val.id);
                  data.set("b_id", val.b_id);
                  data.set("secret", this.secret);
                  await this.Axios.post(url, data)
                    .then((response) => {
                      // console.log(response.data);
                      if (response.data.error == 0) {
                        const INDEX = this.nodes.map((n) => parseInt(n.id)).indexOf(parseInt(val.id));
                        this.nodes.splice(INDEX, 1);
                        this.$swal("Removed " + val.name, "(Mac Address: " + val.mac + ")", "success");
                      } else {
                        this.$swal("", response.data.data, "warning");
                      }
                    })
                    .catch((error) => {
                      this.$swal("", "", "warning");
                      console.error(error);
                    })
                }
              });
          }
        });
    },
    isOnline(node) {
      if (!this.client) {
        setTimeout(() => this.isOnline(node), 1000);
        return false;
      }
      // // let topic = this.topic+node.mac+"/CONTROL";
      // // let payload = `is_online=${this.secret}`;
      // // this.publishedMQTT(topic,payload);

      // let topic = this.topic[node.type] + node.mac + "/CONTROL";
      // let payload = `is_online=${this.secret}`;
      // this.publishedMQTT(topic, payload);

      var url = this.api + 'node/get_online/' + this.secret;
      var data = new FormData();
      data.set('n_id', node.id);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {

            const datetime = new Date(response.data.data.created); // replace with your datetime
            // alert(datetime)
            const now = new Date();

            const diffInMilliseconds = now - datetime;
            const diffInMinutes = diffInMilliseconds / (1000 * 60);

            let index = this.nodes.findIndex(x => x.id == node.id);
            if (index !== -1) {
              console.log("*- isOnline -*", response.data.data, this.nodes[index]);
              this.nodes[index].on = parseInt(response.data.data.val) == 1 ? true : false;

              if (diffInMinutes < 10) {
                this.nodes[index].connect = 1;
              } else {
                this.nodes[index].connect = 0;
              }
            }

          } else {
            // this.$swal(node.name, response.data.data, "warning");
            console.log(node.name, node.mac, response.data.data)
            let index = this.nodes.findIndex(x => x.id == node.id);
            if (index !== -1) this.nodes[index].connect = 0;
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
        .then(() => {
          let minutes = 2;
          setTimeout(() => {
            this.isOnline(node);
          }, minutes * 60 * 1000);
        })

    },
    change_switch(node) {
      let tnj_sc = this.topic.findIndex(x => x.includes("TNJ_SC"));
      let powermeter999 = this.topic.findIndex(x => x.includes("powermeter999"));

      let topic1 = this.topic[tnj_sc] + node.mac + "/CONTROL";
      let topic2 = this.topic[powermeter999] + node.mac + "/CONTROL";
      let payload = (node.on) ? `on=1` : `off=1`;

      this.publishedMQTT(topic1, payload);
      this.publishedMQTT(topic2, payload);
    },
    set_timer(node) {
      let date = new Date();
      let timestamp = Math.floor(date.getTime() / 1000);
      // let timestamp = Date.now();
      let tnj_sc = this.topic.findIndex(x => x.includes("TNJ_SC"));
      let powermeter999 = this.topic.findIndex(x => x.includes("powermeter999"));

      let topic1 = this.topic[tnj_sc] + node.mac + "/CONTROL";
      let topic2 = this.topic[powermeter999] + node.mac + "/CONTROL";
      let payload = `{"Inverter_Remote":"${node.Inverter_Remote}","Time_Start" :"${node.Time_Start}","Time_Stop":"${node.Time_Stop}","percent":"${node.percent}","Time":"${timestamp}"}`;

      this.publishedMQTT(topic1, payload);
      this.publishedMQTT(topic2, payload);
    },
    publishedMQTT(topic, payload) {
      this.client.publish(topic, payload, { qos: 0, retain: false }, (error) => {
        if (error) {
          console.error(error)
        } else {
          console.log(`Published: ${topic} %c${payload}`, 'background: yellow; color: black');
        }
      })
    },
    openTimer(val) {
      this.node = val;
      this.show_timer = true;
    },
    closeTimer() {
      this.node = {};
      this.show_timer = false;
    },
    async saveTimer() {
      if (!this.Inverter_Remote) {
        this.$swal("", "Please set data 'Inverter_Remote'!", "warning");
        return false;
      } else if (this.Inverter_Remote == 'auto') {
        if (this.Time_Start_d == "00") {
          this.$swal("", "Please select day of 'Time_Start'!", "warning");
          return false;
        }
        if (this.Time_Stop_d == "00") {
          this.$swal("", "Please enter day of 'Time_Stop'!", "warning");
          return false;
        }
      }

      var url = this.api + 'node/save/' + this.secret;
      var data = new FormData();
      data.set('id', this.node.id);
      data.set('name', this.node.name);
      data.set('detail', this.node.detail);
      data.set('Inverter_Remote', this.Inverter_Remote);
      if (this.Inverter_Remote == 'auto') {
        data.set('Time_Start', this.Time_Start);
        data.set('Time_Stop', this.Time_Stop);
        data.set('percent', this.percent);
      }
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.node.Inverter_Remote = this.Inverter_Remote;
            if (this.Inverter_Remote == 'auto') {
              this.node.Time_Start = this.Time_Start;
              this.node.Time_Stop = this.Time_Stop;
              this.node.percent = this.percent;
            }
            this.$swal("", "่", "success");

            setTimeout(() => this.set_timer(this.node), 1);
            setTimeout(() => this.closeTimer(), 100);
          } else {
            this.$swal("", response.data.data, "warning");
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
    },
    pad2(number) {
      return (number < 10 ? '0' : '') + number
    },
    formatAMPM(hours) {
      if (hours == "00") {
        return "Mid Night";
      } else if (hours == "12") {
        return "Mid Day";
      } else {
        var h = (hours < 13) ? hours : parseInt(hours) - 12;
        var ampm = hours >= 12 ? 'PM' : 'AM';
        return this.pad2(h) + " " + ampm;
      }
    }
  },
  computed: {
    Nodes() {
      return this.nodes.filter(node => { return parseInt(node.b_id) == parseInt(this.b_id) });
    },
    Time_Start() {
      return this.Time_Start_d + this.Time_Start_h + this.Time_Start_m
    },
    Time_Stop() {
      return this.Time_Stop_d + this.Time_Stop_h + this.Time_Stop_m
    }
  },
  watch: {
    node(val) {
      if (val.id) {
        // if(val.Time_Start=="") val.Time_Start="000000";
        // if(val.Time_Stop=="") val.Time_Stop="000000";
        this.Inverter_Remote = (val.Inverter_Remote) ? val.Inverter_Remote : "manual";
        this.Time_Start_d = (val.Time_Start) ? val.Time_Start.substring(0, 2) : "00";
        this.Time_Start_h = (val.Time_Start) ? val.Time_Start.substring(2, 4) : "00";
        this.Time_Start_m = (val.Time_Start) ? val.Time_Start.substring(4, 6) : "00";
        this.Time_Stop_d = (val.Time_Stop) ? val.Time_Stop.substring(0, 2) : "00";
        this.Time_Stop_h = (val.Time_Stop) ? val.Time_Stop.substring(2, 4) : "00";
        this.Time_Stop_m = (val.Time_Stop) ? val.Time_Stop.substring(4, 6) : "00";
        this.percent = (val.percent) ? val.percent : 0;
      }
    },
  }
}
</script>

<style scoped="">
@import '~bulma-switch';

.border-right {
  border-right: 1px solid #ebe6e6
}

.border-bottom {
  border-bottom: 1px solid #ebe6e6
}
</style>

<style scoped="">
.blobs-container {
  display: flex;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 10px;
  width: 10px;
  margin-left: 8px;
  transform: scale(1);
}

.blob.yellow {
  background: rgb(254, 242, 9);
  box-shadow: 0 0 0 0 rgb(254, 242, 9);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(254, 242, 9);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
</style>